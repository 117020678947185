// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-common-page-js": () => import("./../../../src/pages/common_page.js" /* webpackChunkName: "component---src-pages-common-page-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-details-page-js": () => import("./../../../src/pages/details_page.js" /* webpackChunkName: "component---src-pages-details-page-js" */),
  "component---src-pages-in-design-page-test-2-js": () => import("./../../../src/pages/in_design_page_test2.js" /* webpackChunkName: "component---src-pages-in-design-page-test-2-js" */),
  "component---src-pages-in-design-page-test-js": () => import("./../../../src/pages/in_design_page_test.js" /* webpackChunkName: "component---src-pages-in-design-page-test-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

